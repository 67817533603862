import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { lazy, Suspense } from "react";

//route guards
import { ProtectedRoute } from "../guards/ProtectedRoutes.jsx";
import { OpenRoutes } from "../guards/OpenRoutes.jsx";
import { ProgressGuard } from "../guards/ProgressGuard.jsx";

//layouts
import AuthLayout from "../layouts/AuthLayout.jsx";
import { PageLayout } from "../layouts/PageLayout.jsx";

//lazy imports
const Home = lazy(() => import("../pages/Home.jsx"));
const Dashboard = lazy(() => import("../pages/Dashboard.jsx"));
const BusinessDetails = lazy(() => import("../pages/BusinessDetails.jsx"));
const FoodTruck = lazy(() => import("../pages/FoodTruck.jsx"));
const Schedule = lazy(() => import("../pages/Schedule.jsx"));
const MenuSetup = lazy(() => import("../pages/MenuSetup.jsx"));
const UserRole = lazy(() => import("../pages/UserRole.jsx"));
const Reports = lazy(() => import("../pages/Reports.jsx"));
const QrGenerator = lazy(() => import("../pages/QrGenerator.jsx"));
const SocialMedia = lazy(() => import("../pages/SocialMedia.jsx"));
const Support = lazy(() => import("../pages/Support.jsx"));

//static pages
const AboutUs = lazy(() => import("../pages/static/AboutUs.jsx"));
const SecurityPolicy = lazy(() => import("../pages/static/SecurityPolicy.jsx"));
const PrivacyPolicy = lazy(() => import("../pages/static/PrivacyPolicy.jsx"));
const Terms = lazy(() => import("../pages/static/Terms.jsx"));
const Help = lazy(() => import("../pages/static/Help.jsx"));

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route element={<OpenRoutes />}>
          <Route
            path="home"
            element={
              <Suspense fallback="Loading...">
                {" "}
                <AuthLayout /> -
              </Suspense>
            }
          >
            <Route path="" element={<Home />} />
            <Route
              path="about_us"
              element={
                <Suspense fallback="Loading...">
                  <AboutUs />
                </Suspense>
              }
            />
            <Route
              path="terms_and_condition-bms"
              element={
                <Suspense fallback="Loading...">
                  <Terms />
                </Suspense>
              }
            />
            <Route
              path="terms_and_condition-fos"
              element={
                <Suspense fallback="Loading...">
                  <Terms />
                </Suspense>
              }
            />
            <Route
              path="privacy_policy"
              element={
                <Suspense fallback="Loading...">
                  <PrivacyPolicy />
                </Suspense>
              }
            />
            <Route
              path="security_policy"
              element={
                <Suspense fallback="Loading...">
                  <SecurityPolicy />
                </Suspense>
              }
            />
            <Route
              path="help"
              element={
                <Suspense fallback="Loading...">
                  <Help />
                </Suspense>
              }
            />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>
          <Route path="*" element={<Navigate to="home" replace />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path="app"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <PageLayout />{" "}
              </Suspense>
            }
          >
            <Route path="business" element={<BusinessDetails />} />
            <Route path="dashboard" element={<ProgressGuard />}>
              <Route
                path=""
                element={
                  <Suspense fallback="Loading...">
                    {" "}
                    <Dashboard />{" "}
                  </Suspense>
                }
              />
            </Route>
            <Route path="food_truck" element={<ProgressGuard />}>
              <Route
                path=""
                element={
                  <Suspense fallback="Loading...">
                    {" "}
                    <FoodTruck />{" "}
                  </Suspense>
                }
              />
            </Route>
            <Route path="schedule" element={<ProgressGuard />}>
              <Route
                path=""
                element={
                  <Suspense fallback="Loading...">
                    {" "}
                    <Schedule />{" "}
                  </Suspense>
                }
              />
            </Route>
            <Route path="menu-setup" element={<ProgressGuard />}>
              <Route
                path=""
                element={
                  <Suspense fallback="Loading...">
                    {" "}
                    <MenuSetup />{" "}
                  </Suspense>
                }
              />
            </Route>
            <Route path="user-role" element={<ProgressGuard />}>
              <Route
                path=""
                element={
                  <Suspense fallback="Loading...">
                    {" "}
                    <UserRole />{" "}
                  </Suspense>
                }
              />
            </Route>

            <Route path="reports" element={<ProgressGuard />}>
              <Route
                path=""
                element={
                  <Suspense fallback="Loading...">
                    {" "}
                    <Reports />{" "}
                  </Suspense>
                }
              />
            </Route>
            <Route path="qr-generator" element={<ProgressGuard />}>
              <Route
                path=""
                element={
                  <Suspense fallback="Loading...">
                    {" "}
                    <QrGenerator />{" "}
                  </Suspense>
                }
              />
            </Route>

            <Route path="social-media" element={<ProgressGuard />}>
              <Route
                path=""
                element={
                  <Suspense fallback="Loading...">
                    {" "}
                    <SocialMedia />{" "}
                  </Suspense>
                }
              />
            </Route>
            <Route path="support" element={<ProgressGuard />}>
              <Route
                path=""
                element={
                  <Suspense fallback="Loading...">
                    {" "}
                    <Support />{" "}
                  </Suspense>
                }
              />
            </Route>
            <Route path="*" element={<Navigate to="dashboard" replace />} />
            <Route path="" element={<Navigate to="dashboard" replace />} />
          </Route>
          <Route path="*" element={<Navigate to="app" replace />} />
        </Route>
        <Route path="*" element={<Navigate to="home" replace />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
