import { useNavigate } from "react-router";
import { setLogout } from "../store/reducers/user_store";
import { useDispatch } from "react-redux";

export default function useLogout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = async () => {
    localStorage.setItem("BMS_enc", "");
    dispatch(setLogout());
    navigate("/home", { replace: true });
  };
  return { logout };
}
